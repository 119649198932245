import { TotalItem, TotalsProps } from 'models/interfaces/workloadCalendar.interface';
import styles from './workloadStyle.module.scss';

const TotalsBlock = ({ data }: TotalsProps) => {
  return (
    <div className={styles.block}>
      {data.map((totalItem: TotalItem) => (
        <div key={totalItem.title} className={styles.blockItem} style={{ backgroundColor: totalItem.color }}>
          <p>{totalItem.hours}</p>
          <span>{totalItem.title}</span>
        </div>
      ))}
    </div>
  );
};

export default TotalsBlock;
