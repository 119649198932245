import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import Datepicker from 'components/Datepicker/Datepicker';
import Loader from 'common/Loader';
import Pagination from 'components/Pagination/Pagination';
import CompensationOrdersTable from './CompensationOrdersTable';

import { getCompensationOrders } from 'store/compensationOrders/compensationOrders.thunk';
import { AppState } from 'store/configureStore';
import { OrdersRequestParams } from 'models/compensationOrders.interface';

import { useQuery } from 'hooks/queryHook';
import { DATE_PICKER } from 'constants/common';
import { getStartAndEndDateOfPreviousMonth } from 'utils/dates';

import styles from '../tableStyles.module.scss';

const ORDER_STATUSES: string[] = [
  'All',
  'Draft',
  'Needs approval',
  'Sent for approval',
  'Invalid obligations',
  'Pending',
  'Sent',
  'Confirmed',
  'Rejected',
];

const CompensationOrders: FC = () => {
  const dispatch = useDispatch();

  const { compensationOrders, pages, total, loading } = useSelector(
    (state: AppState) => state.compensationOrdersReducer
  );

  const { page, pageSize, fromDate, toDate, status, setPage, setPageSize, setDateRange, setStatus } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      const [previousMonthStartDate, previousMonthEndDate] = getStartAndEndDateOfPreviousMonth();
      setDateRange(previousMonthStartDate, previousMonthEndDate);
    } else {
      const params: OrdersRequestParams = {
        fromDate: fromDate,
        toDate: toDate,
        page: page ? +page : 1,
        size: pageSize ? +pageSize : 50,
        expand: 'compensations',
      };

      if (status) {
        params.status = status;
      }

      dispatch(getCompensationOrders(params));
    }
  }, [page, pageSize, fromDate, toDate, status]);

  useEffect(() => {
    getData();
  }, [getData]);

  const setCurrentPageHandler = (value: number) => {
    if (value !== +page) {
      setPage(value);
    }
  };

  const onDateChange = (date: Date) => {
    setDateRange(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  const onStatusChange = (value: string) => {
    setStatus(value === 'All' ? '' : value.toLowerCase().split(' ').join('-'));
  };

  const statusValue = ORDER_STATUSES?.find((item: string) => item.toLowerCase().split(' ').join('-') === status);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={!!fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>

            <div className="dropdown-filter">
              <div className="label-wrapper">Status</div>
              <DropdownList
                containerClassName={cx(styles.inputsField, styles.dropdown)}
                data={ORDER_STATUSES}
                value={statusValue}
                placeholder="All"
                filter="contains"
                onChange={onStatusChange}
              />
            </div>
          </div>
        </div>

        {compensationOrders?.length ? (
          <>
            <div className={cx(styles.tableWrapper, 'mb-1')} style={{ overflow: 'unset' }}>
              <CompensationOrdersTable
                data={compensationOrders || []}
                loading={loading}
                currentPage={+page}
                perPage={+pageSize}
              />

              <div className={styles.marginBottomTable}>
                <Pagination
                  pages={pages}
                  perPage={+pageSize}
                  totalCount={total}
                  setCurrentPage={setCurrentPageHandler}
                  currentPage={+page}
                  setCountPerPage={(value: number) => setPageSize(value)}
                  count={0}
                />
              </div>
            </div>
          </>
        ) : loading ? (
          <Loader />
        ) : (
          'Compensation orders are empty'
        )}
      </CardBody>
    </Card>
  );
};

export default CompensationOrders;
