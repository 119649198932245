import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DropdownToggle, DropdownMenu, Button, Dropdown } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import { changeUserRole } from 'store/account';
import { enqueueSnackbar } from 'store/notifications';
import { NotificationType } from 'services/notificationService';
import routes from 'config/routes';
import { findRouteMatch } from 'utils/findRouteMatch';
import { Role } from 'constants/roles';

import avatarUser from '../../../assets/utils/images/avatars/avatar.png';
import './userBoxStyle.scss';

type UserBoxProps = {
  username: string;
  role: string;
  availableRoles: string[];
};

const UserBox = ({ username, role, availableRoles }: UserBoxProps) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    Auth.signOut();
    localStorage.removeItem('selectedRole');
  };
  const history = useHistory();
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = (): void => setDropdownOpen(prevState => !prevState);
  const goToProfile = (): void => {
    toggle();
    history.push('/dashboard/profile');
    sessionStorage.removeItem('navigation');
  };

  const backendVersion = sessionStorage.getItem('backendVersion');
  const userWithMultipleRoles = availableRoles.length > 1;

  const changeRole = (role: Role) => {
    localStorage.setItem('selectedRole', role);
    dispatch(changeUserRole(role));
    const matchingRoute = findRouteMatch(location.pathname, routes);

    if (matchingRoute && matchingRoute.permissions.includes(role)) {
      toggle();
      history.replace(`${location.pathname}`);
    } else {
      goToProfile();
    }

    dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Role changed successfully!',
          title: 'Role',
        },
      })
    );
  };

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="link" className="p-0">
                  <img width={42} className="rounded-circle" src={avatarUser} alt="" />
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                </DropdownToggle>

                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={avatarUser} alt="" />
                            </div>

                            <div className="widget-content-left">
                              <div className="widget-heading name-curtail">{username}</div>
                              {userWithMultipleRoles ? (
                                <div className="role-dropdown-wrap">
                                  <DropdownList
                                    value={role}
                                    data={availableRoles}
                                    onChange={value => changeRole(value)}
                                  />
                                </div>
                              ) : (
                                <div role="button" className="widget-subheading opacity-8">
                                  {role}
                                </div>
                              )}
                            </div>

                            <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill btn-shadow btn-shine mr-2"
                                onClick={goToProfile}
                                color="focus"
                              >
                                Profile
                              </Button>

                              <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={handleLogout}>
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading name-curtail">{username}</div>
              <div className="widget-subheading" style={{ fontSize: '10px' }}>
                <p className="mb-0" style={{ color: '#6c757d' }}>
                  FE: MASTER-273-24.08.23
                </p>
                <p style={{ color: '#6c757d' }}>{`BE: ${backendVersion}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
