import { format } from 'date-fns';
import { getDatesRange, secondsToHours } from 'utils/dates';
import { DATE_PICKER } from 'constants/common';
import {
  AbsenceT,
  CalendarTdInfo,
  EmployeeResourceRequests,
  EmployeeVsd,
  FactualWorklog,
  InitialWorklog,
  PlannedRR,
  PlannedWorkloadParams,
  ResourceRequestItem,
  ShiftT,
  TotalItem,
  TotalsT,
  WorklogT,
} from 'models/interfaces/workloadCalendar.interface';
import { CalendarDayInterface } from 'models/interfaces/calendar-day.interface';
import { COLORS } from 'config/theme';

export const getAbsencesDates = (absences: EmployeeVsd[]) => {
  const dateRange: AbsenceT[] = [];

  absences.forEach((item: EmployeeVsd) => {
    if (item.issueType === 'Day off') {
      let dates = getDatesRange(item.startDate, item.endDate);

      dates.forEach(date => {
        dateRange.push({
          date,
          status: item.status,
          issueType: item.issueType,
        });
      });
    }
  });

  return dateRange;
};

export const getEmployeeShifts = (data: EmployeeVsd[]) => {
  const shifts: ShiftT[] = [];

  data.forEach((item: EmployeeVsd) => {
    if (item.issueType === 'Shift') {
      shifts.push({
        startDate: item.startDate,
        endDate: item.endDate,
        status: item.status,
        issueType: item.issueType,
      });
    }
  });

  return shifts;
};

export const formatResourceRequests = (data: EmployeeResourceRequests[]) => {
  const formattedItems = data.reduce((acc: ResourceRequestItem[], item: EmployeeResourceRequests) => {
    const existingItem = acc.find((value: ResourceRequestItem) => value.linkedProjectKey === item.linkedProjectKey);
    const date = getDatesRange(item.startDate, item.endDate);
    const rrDatesRange = date.map(date => ({ date, timePerDay: item.timePerDay }));

    if (existingItem) {
      existingItem.rrDatesRange.push(...rrDatesRange);
      // check if payment type already exists
      if (existingItem.paymentType.hasOwnProperty(item.paymentType)) {
        existingItem.paymentType[item.paymentType].estimatedBillableTime =
          existingItem.paymentType[item.paymentType].estimatedBillableTime + item.estimatedBillableTime;
      } else {
        // add new payment type
        existingItem.paymentType[item.paymentType] = { estimatedBillableTime: item.estimatedBillableTime };
      }
    } else {
      acc.push({
        linkedProjectKey: item.linkedProjectKey,
        paymentType: { [item.paymentType]: { estimatedBillableTime: item.estimatedBillableTime } },
        rrDatesRange,
        totalLoggedTime: item.totalLoggedTime,
        worklogs: item.worklogs,
      });
    }

    return acc;
  }, []);

  return formattedItems;
};

export const formatProjectResourceRequests = (resourceRequests: EmployeeResourceRequests[]) => {
  const formattedData = resourceRequests.map((item: EmployeeResourceRequests) => {
    const dates = getDatesRange(item.startDate, item.endDate);
    const rrDatesRange = dates.map(date => ({ date, timePerDay: item.timePerDay }));

    const isUniqueProject = resourceRequests.filter(projectRR => projectRR.linkedProjectKey === item.linkedProjectKey);

    if (isUniqueProject?.length > 1) {
      let totalLoggedTime = 0;

      const worklogs = rrDatesRange.reduce((acc: FactualWorklog[], resourceRequest: PlannedRR) => {
        const currentWorklogs = item.worklogs?.filter(
          (worklog: FactualWorklog) => worklog.date === resourceRequest.date
        );

        if (currentWorklogs?.length) {
          const worklogTimeForCurrentDay = currentWorklogs.reduce(
            (acc: number, worklog: FactualWorklog) => acc + worklog.worklogTime,
            0
          );

          acc.push({ date: currentWorklogs[0].date, worklogTime: worklogTimeForCurrentDay });
          totalLoggedTime += worklogTimeForCurrentDay;
        }

        return acc;
      }, []);

      return {
        ...item,
        totalLoggedTime,
        worklogs,
        rrDatesRange,
      };
    } else {
      let totalLoggedTime = 0;

      const worklogs = item.worklogs.reduce((acc: FactualWorklog[], worklog: FactualWorklog) => {
        const existingDate = acc?.findIndex((worklogItem: FactualWorklog) => worklogItem.date === worklog.date);

        if (existingDate === -1) {
          totalLoggedTime += worklog.worklogTime;
          acc.push({ date: worklog.date, worklogTime: worklog.worklogTime });
        } else {
          totalLoggedTime += worklog.worklogTime;
          acc[existingDate].worklogTime = acc[existingDate].worklogTime + worklog.worklogTime;
        }

        return acc;
      }, []);

      return {
        ...item,
        totalLoggedTime,
        worklogs,
        rrDatesRange,
      };
    }
  });

  return formattedData;
};

const getTotalWorklogSum = (worklogs: InitialWorklog) => {
  let sum = 0;

  for (const employeeIdKey in worklogs) {
    const projectsData = worklogs[employeeIdKey];

    for (const projectKey in projectsData) {
      const list = projectsData[projectKey];

      for (const item of list) {
        if (item.worklogTime) {
          sum += item.worklogTime;
        }
      }
    }
  }

  return sum;
};

export const getTotals = (totals: TotalsT, worklogs: InitialWorklog) => {
  const data: TotalItem[] = [
    {
      title: 'Hours',
      hours: secondsToHours(totals.working),
      color: COLORS.darkGray,
    },
    {
      title: 'FTE',
      hours: String(totals.fte),
      color: COLORS.orange,
    },
    {
      title: 'Billable RR fact',
      hours: secondsToHours(totals.factualBillable),
      color: COLORS.teal,
    },
    {
      title: 'Billable RR',
      hours: secondsToHours(totals.workloadBillable),
      color: COLORS.green,
    },
    {
      title: 'Non-Billable RR',
      hours: secondsToHours(totals.workloadNonBillable),
      color: COLORS.red,
    },
    {
      title: 'VSD',
      hours: `${((totals.absence * 100) / totals.working).toFixed(1)}%`,
      color: COLORS.mdGray,
    },
    {
      title: 'Logs',
      hours: secondsToHours(getTotalWorklogSum(worklogs)),
      color: COLORS.blue,
    },
    {
      title: 'Downtime',
      hours: secondsToHours(totals.available),
      color: COLORS.purple,
    },
  ];
  return data;
};

export const formatWorklogs = (worklogs: WorklogT[]) => {
  const formattedLogs: FactualWorklog[] = [];

  worklogs.forEach((worklog: WorklogT) => {
    const dateInKyivTimeZone = new Date(worklog.startedAt).toLocaleString('en-US', { timeZone: 'Europe/Kiev' });
    const date = format(new Date(dateInKyivTimeZone), DATE_PICKER.dateFormatToPayload);

    formattedLogs.push({ date: date, worklogTime: worklog.worklogTime });
  });

  return formattedLogs;
};

const getWorklogType = (factTime: number, plannedTime: number) => {
  if (factTime > plannedTime) {
    return 'loggedMore';
  } else if (factTime < plannedTime) {
    return 'loggedLess';
  } else if (factTime === plannedTime) {
    return 'correctLog';
  } else {
    return 'none';
  }
};

const checkDowntime = (startDate: string, endDate: string, currentDate: string) => {
  // endDate can be an empty string
  if (currentDate < startDate || (!!endDate && endDate < currentDate)) {
    return '-';
  } else {
    return 'D';
  }
};

export const getPlannedWorkload = (params: PlannedWorkloadParams) => {
  const today = format(new Date(), DATE_PICKER.dateFormatToPayload);

  const items = params.dateRange.map((dateItem: CalendarDayInterface) => {
    const newItem: CalendarTdInfo = {
      isHoliday: dateItem.isHoliday,
      isCurrentDay: dateItem.isCurrentDay,
      issue: '-',
      issueType: 'holiday',
    };

    const isShifted = params.shifts.find((shift: ShiftT) => shift.endDate === dateItem.date);

    if (isShifted) {
      if (dateItem.date < today) {
        const worklog = params.worklogs.find((worklog: FactualWorklog) => worklog.date === dateItem.date);
        newItem.issueType = 'shifted';

        if (worklog) {
          newItem.issue = secondsToHours(worklog.worklogTime);
        } else {
          newItem.issue = '0';
        }
      } else {
        newItem.issue = 'S';
        newItem.issueType = isShifted.status === 'Approved' ? 'shifted' : 'none';
      }
    } else if (!newItem.isHoliday) {
      const isVsd = params.absences.find((vsd: AbsenceT) => vsd.date === dateItem.date);
      if (isVsd) {
        newItem.issue = 'V';
        newItem.issueType =
          isVsd.status !== 'Waiting for confirmation' ? 'dayOff' : params.isProjectRow ? 'project' : 'none';
      }

      const isShift = params.shifts.find((shift: ShiftT) => shift.startDate === dateItem.date);
      if (isShift) {
        newItem.issue = 'S';
        newItem.issueType = isShift.status === 'Approved' ? 'shift' : params.isProjectRow ? 'project' : 'none';
      }

      const hasRR = params.resourceRequests.find((rr: PlannedRR) => rr.date === dateItem.date);

      if (dateItem.date < today && !isVsd && !isShift) {
        const worklog = params.worklogs.find((worklog: FactualWorklog) => worklog.date === dateItem.date);

        if (worklog) {
          newItem.issue = secondsToHours(worklog.worklogTime);
          newItem.issueType = getWorklogType(worklog.worklogTime, hasRR?.timePerDay ? hasRR.timePerDay : 0);
        } else {
          newItem.issue = '0';
          newItem.issueType = 'loggedLess';
        }
      } else if (hasRR && !isVsd && !isShift) {
        newItem.issue = secondsToHours(hasRR.timePerDay);
        newItem.issueType = params.isProjectRow ? 'project' : 'none';
      }

      if (!isVsd && !isShift && !isShifted && !hasRR) {
        if (!params.isProjectRow) {
          let downtimeItem = checkDowntime(params.startDate, params.endDate, dateItem.date);

          newItem.issue = checkDowntime(params.startDate, params.endDate, dateItem.date);
          newItem.issueType = downtimeItem === 'D' ? 'downtime' : 'none';
        } else {
          newItem.issue = '-';
          newItem.issueType = 'project';
        }
      }
    }

    return newItem;
  });

  return items;
};

export const getProjectsTotalRRs = (data: ResourceRequestItem[]) => {
  const totalRRs = data.reduce((acc: PlannedRR[], project: ResourceRequestItem) => {
    project.rrDatesRange.forEach((projectRR: PlannedRR) => {
      const existingDate = acc.findIndex((item: PlannedRR) => item.date === projectRR.date);

      if (existingDate === -1) {
        acc.push({ ...projectRR });
      } else {
        acc[existingDate].timePerDay = acc[existingDate].timePerDay + projectRR.timePerDay;
      }
    });

    return acc;
  }, []);

  return totalRRs;
};

export const getEmployeeTotalWorklogs = (data: ResourceRequestItem[]) => {
  const totalWorklogs = data.reduce((acc: FactualWorklog[], project: ResourceRequestItem) => {
    project.worklogs.forEach((worklog: FactualWorklog) => {
      const existingDate = acc.findIndex((item: FactualWorklog) => item.date === worklog.date);

      if (existingDate === -1) {
        acc.push({ date: worklog.date, worklogTime: worklog.worklogTime });
      } else {
        acc[existingDate].worklogTime = acc[existingDate].worklogTime + worklog.worklogTime;
      }
    });

    return acc;
  }, []);

  return totalWorklogs;
};

export const getBorderColor = (item: CalendarTdInfo) => {
  if (item.issueType === 'shifted' && item.isCurrentDay) {
    return '#51BED8 #4F75FF';
  } else if (item.issueType === 'shifted') {
    return '#51BED8';
  } else if (item.isCurrentDay) {
    return '#E6E7ED #4F75FF';
  } else {
    return '#E6E7ED';
  }
};

export const getHoverColor = (type: string) => {
  switch (type) {
    case 'downtime':
      return '#E6DBFF';
    case 'loggedMore':
      return '#FFDCDE';
    case 'loggedLess':
      return '#FFEDDA';
    case 'correctLog':
      return '#DAF4E1';
    case 'dayOff':
      return '#E6E7ED';
    case 'shift':
      return '#DCF2F7';
    default:
      return '#DCE3FF';
  }
};
