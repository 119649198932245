import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import TeamModel from '../../models/team.model';
import teamService from '../../services/teamService';

interface TeamsState {
  loading: boolean;
  teams: TeamModel[] | null;
  error: Error | null;
}

const initialState: TeamsState = {
  loading: false,
  teams: null,
  error: null,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    getTeamsListRequest: (state: TeamsState) => {
      return {
        ...state,
        loading: true,
        teams: null,
        error: null,
      };
    },
    getTeamsListSuccess: (state: TeamsState, action: PayloadAction<TeamModel[]>) => {
      return {
        ...state,
        teams: action.payload,
        loading: false,
        error: null,
      };
    },
    getTeamsListFailure: (state: TeamsState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        teams: null,
        error: action.payload,
      };
    },
  },
});

export const { getTeamsListRequest, getTeamsListSuccess, getTeamsListFailure } = teamsSlice.actions;

export const getTeamsListThunk =
  (params?: { expand?: string; planning?: boolean }) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getTeamsListRequest());
    try {
      const teams: TeamModel[] = await teamService.getTeamsList(params);
      dispatch(getTeamsListSuccess(teams));
    } catch (err) {
      dispatch(getTeamsListFailure(err as Error));
    }
  };

export default teamsSlice.reducer;
