import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import Loader from 'common/Loader';
import TeamCard from './TeamCard';
import { TeamEditing } from './TeamProfile/TeamEditing';

import { sortTeamsData } from 'helpers/companyStructureHeplers';
import TeamModel from 'models/team.model';
import { Role } from 'constants/roles';

import { createTeam } from 'store/companyStucture/companyStructure.thunk';
import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { getTeamsListThunk } from 'store/teams';
import { AppState } from 'store/configureStore';
import { EmployeeInfoT } from 'store/eventsEmployees';
import { plus } from 'constants/icons';
import { DATE_PICKER } from 'constants/common';

import styles from './companyStructure.module.scss';
import tableStyles from '../tableStyles.module.scss';

const CompanyStructure: FC = () => {
  const dispatch = useDispatch();

  const [showCreateTeam, setShowCreateTeam] = useState<boolean>(false);

  const { teams, loading } = useSelector((state: AppState) => state.teamsReducer);
  const { employees, loading: loadingEmployee } = useSelector((state: AppState) => state.allEmployeesReducer);

  const role = useSelector((state: AppState) => state.account.user.role);
  const canCreate = role === Role.vp || role === Role.hr;

  const getCompanyStructure = () => {
    dispatch(getTeamsListThunk({ expand: 'type,parent,children' }));
    dispatch(getEmployees({ params: { date: format(new Date(), DATE_PICKER.dateFormatToPayload) } }));
  };

  useEffect(() => {
    getCompanyStructure();
  }, []);

  const closeCreateMode = () => setShowCreateTeam(false);
  const showCreateMode = () => setShowCreateTeam(true);

  const onSubmit = (values: Omit<TeamModel, 'id'>) => {
    const {
      title,
      typeId,
      email,
      isAvailableForPlanning,
      openDate,
      closeDate,
      parentId,
      teamleadId,
      members,
      children,
    } = values;
    const team = { title, typeId, email, isAvailableForPlanning, openDate, closeDate, parentId, teamleadId };

    dispatch(createTeam({ team, newMembers: members, childTeams: children }));
    setShowCreateTeam(false);
    dispatch(getCompanyStructure());
  };

  const ceo = employees?.find((employee: EmployeeInfoT) => employee.title === 'CEO');

  const { teamWithChildren, sortedTeams } = sortTeamsData(teams);

  if (loading || loadingEmployee) return <Loader />;

  if (showCreateTeam) {
    return (
      <TeamEditing
        teams={teams}
        employees={employees}
        onClose={closeCreateMode}
        buttonName="create"
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <>
      {canCreate && (
        <div className={tableStyles.buttonWrap}>
          <button className={cx(tableStyles.button, tableStyles.topButton)} onClick={showCreateMode}>
            {plus} Create team
          </button>
        </div>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          <div className="mb-4 mt-2">
            {ceo && (
              <div className={cx(styles.team, styles.ceo)}>
                CEO
                <span className={styles.circle} />
                <Link to={`/dashboard/profile/${ceo.id}`}>{`${ceo.firstName} ${ceo.lastName}`}</Link>
              </div>
            )}
          </div>
          <div className={styles.teamsWrapper}>
            {teamWithChildren && <TeamCard team={teamWithChildren} employees={employees} />}

            <div className={styles.departmentsList}>
              {sortedTeams?.length
                ? sortedTeams.map((team: TeamModel) => {
                    return <TeamCard key={team.id} team={team} employees={employees} />;
                  })
                : null}
            </div>
          </div>
          <div className={cx(styles.department, 'mt-3')}>Total - {employees?.length}</div>
        </CardBody>
      </Card>
    </>
  );
};

export default CompanyStructure;
