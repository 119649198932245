import { TeamT } from 'models/dto/team.dto';
import TeamModel from 'models/team.model';

const getFlatListOfTeams = (teams: TeamModel[], level = 0) => {
  let flatList: TeamT[] = [];

  teams.forEach(team => {
    if (team.children?.length) {
      flatList.push({ ...team, level, hasChildren: true });
      flatList = flatList.concat(getFlatListOfTeams(team.children, level + 1));
    } else {
      flatList.unshift({ ...team, level });
    }
  });
  return flatList;
};

export const formatTeamsData = (teams: TeamModel[], teamList?: string) => {
  let rootTeams = teams.filter((team: TeamModel) => !team.parentId);

  const flatTeamsList: TeamT[] = getFlatListOfTeams(rootTeams);

  if (teamList) {
    const teamArr = teamList.split(',');
    const teamLeadTeams = flatTeamsList.filter((team: TeamT) => teamArr.includes(String(team.id)));

    return teamLeadTeams;
  }

  return [{ id: 0, title: 'All', level: 0 }, ...flatTeamsList];
};
