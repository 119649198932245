import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { DropdownList } from 'react-widgets';
import TeamsWithChildren from './TeamsWithChildren';

import { AppState } from 'store/configureStore';
import { getTeamsListThunk } from 'store/teams';

import { formatTeamsData } from './constants';
import { TeamT } from 'models/dto/team.dto';

import styles from '../../components/tableStyles.module.scss';
interface TeamFilterProps {
  team: string;
  setTeam: (value: string, searchData?: string) => void;
  planning?: boolean;
  searchData?: string;
  teamLeadTeams?: string;
  setSearchData?: (value: string) => void;
}

const TeamFilter: FC<TeamFilterProps> = ({ team, setTeam, planning, searchData, setSearchData, teamLeadTeams }) => {
  const dispatch = useDispatch();

  const { teams } = useSelector((state: AppState) => state.teamsReducer);

  useEffect(() => {
    dispatch(getTeamsListThunk({ expand: 'parent,children', planning }));
  }, []);

  const formattedTeams: TeamT[] = useMemo(() => {
    if (teams) {
      return teamLeadTeams ? formatTeamsData(teams, teamLeadTeams) : formatTeamsData(teams);
    } else {
      return [];
    }
  }, [teams]);

  const dropdownValue = formattedTeams?.find((item: TeamT) => item.id === +team);

  const onTeamChange = (team: TeamT) => {
    // clear search input (for pages where filters applied separately)
    if (setSearchData) {
      setSearchData('');
    }

    setTeam(team.id ? String(team.id) : '', searchData);
  };

  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">Team</p>
      <DropdownList
        containerClassName={cx(styles.inputsField, styles.dropdown)}
        value={dropdownValue || ''}
        placeholder="All"
        data={formattedTeams}
        textField="title"
        filter="contains"
        itemComponent={TeamsWithChildren}
        onChange={onTeamChange}
        busy={!teams}
      />
    </div>
  );
};

export default TeamFilter;
