import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import WorkloadCalendarTable from './Table/WorkloadCalendarTable';
import TotalsBlock from './TotalsBlock';
import LegendBlock from './LegendBlock';
import Datepicker from 'components/Datepicker/Datepicker';
import Loader from 'common/Loader';
import TeamFilter from 'common/TeamFilter/TeamFilter';

import { getWorkloadCalendar } from 'store/workloadCalendar/workloadCalendar.thunk';
import { getCalendarThunk } from 'store/calendar';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { RequestParams } from 'utils/mapParams';
import { DATE_PICKER, RESOURCE_REQUEST_STATUSES } from 'constants/common';
import { formatWorklogs, getAbsencesDates, getEmployeeShifts, getTotals } from './constants';
import {
  InitialWorkloadItem,
  WorkloadTeamItem,
  TransformedWorkloadItem,
  WorklogT,
  EmployeeResourceRequests,
} from 'models/interfaces/workloadCalendar.interface';

const WorkloadCalendar = () => {
  const dispatch = useDispatch();

  const { workloadData, totals, worklogs, loading } = useSelector((state: AppState) => state.workloadCalendarReducer);
  const { selectedMonthDaysList } = useSelector((state: AppState) => state.calendarReducer);

  const [loadingTable, setLoadingTable] = useState(true);

  const { fromDate, toDate, team, status, setFromToDate, setTeam, setStatusValue } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: Partial<RequestParams> = {
        fromDate: fromDate,
        toDate: toDate,
        expand: 'rr,absence',
      };

      if (+team !== 0) {
        params.team = +team;
      }
      if (status) {
        params.status = status;
      }

      dispatch(getWorkloadCalendar(params));
      dispatch(getCalendarThunk({ fromDate: fromDate, toDate: toDate }));
    }
  }, [fromDate, toDate, team, status]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onDateChange = (date: Date) => {
    setFromToDate(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  const onStatusChange = (status: string) => {
    setStatusValue(status === 'All' ? '' : status.toLocaleLowerCase().split(' ').join('-'));
  };

  const transformedWorkloadData = useMemo(() => {
    if (!!workloadData) {
      // sort employees by teams
      const transformedData = workloadData.reduce((acc: WorkloadTeamItem[], item: InitialWorkloadItem) => {
        const teamId = item?.employee?.team?.id;
        const teamTitle = item?.employee?.team?.title;
        const absences = getAbsencesDates(item.absences);
        const shifts = getEmployeeShifts(item.absences);
        const resourceRequests = item.resourceRequests;
        const employee = {
          absence: item.absence,
          available: item.available,
          resourceRequests: resourceRequests,
          availableRemaining: item.availableRemaining,
          absences: absences,
          shifts: shifts,
          fte: item.fte,
          workloadNonBillable: item.workloadNonBillable,
          workloadBillable: item.workloadBillable,
          working: item.working,
          firstName: item.employee.firstName,
          atlassianId: item.employee.atlassianId,
          startDate: item.employee.startDate,
          dismissalDate: item.employee?.dismissalDate ? item.employee.dismissalDate : '',
          lastName: item.employee.lastName,
          id: item.employee.id,
        };

        const teamIndex = acc.findIndex((team: WorkloadTeamItem) => team.id === teamId);

        if (teamIndex === -1) {
          acc.push({
            id: teamId,
            title: teamTitle,
            employees: [employee],
          });
        } else {
          acc[teamIndex].employees.push(employee);
        }

        return acc;
      }, []);

      if (worklogs !== null) {
        // add employee's worklogs
        transformedData.map((team: WorkloadTeamItem) => {
          team.employees.forEach((employee: TransformedWorkloadItem) => {
            if (worklogs[employee.atlassianId]) {
              // checks if there are any logs for the current employee
              employee.resourceRequests = employee.resourceRequests.map((project: EmployeeResourceRequests) => {
                if (worklogs[employee.atlassianId][project.linkedProjectKey]) {
                  // checks if there are any logs for the current project
                  // count total logged time for the project
                  const totalWorklogs = worklogs[employee.atlassianId][project.linkedProjectKey].reduce(
                    (acc: number, worklog: WorklogT) => acc + worklog.worklogTime,
                    0
                  );

                  return {
                    ...project,
                    totalLoggedTime: totalWorklogs,
                    worklogs: formatWorklogs(worklogs[employee.atlassianId][project.linkedProjectKey]), // add array with worklogs
                  };
                } else {
                  return project;
                }
              });
            }
          });
        });
      }

      return { transformedData };
    }
  }, [workloadData, worklogs]);

  const totalsData = useMemo(() => {
    if (totals) {
      return getTotals(totals, worklogs);
    }
  }, [totals, worklogs]);

  const statusValue = RESOURCE_REQUEST_STATUSES.find(
    (item: string) => item.toLocaleLowerCase().split(' ').join('-') === status
  );

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={!!fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>

            <TeamFilter team={team} setTeam={setTeam} planning={true} />

            <div className="dropdown-filter">
              <div className="label-wrapper">Status</div>
              <DropdownList
                value={statusValue ? statusValue : ''}
                data={RESOURCE_REQUEST_STATUSES}
                placeholder="All"
                filter="contains"
                onChange={onStatusChange}
              />
            </div>
          </div>
        </div>

        {workloadData !== null && transformedWorkloadData?.transformedData?.length ? (
          <>
            <TotalsBlock data={totalsData || []} />
            <WorkloadCalendarTable
              data={transformedWorkloadData.transformedData}
              dateRange={selectedMonthDaysList || []}
              setLoadingTable={setLoadingTable}
            />
            <LegendBlock />
          </>
        ) : !workloadData?.length && !loading && !loadingTable ? (
          'Workload Calendar is empty'
        ) : (
          <Loader />
        )}
      </CardBody>
    </Card>
  );
};

export default WorkloadCalendar;
